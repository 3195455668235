import React from 'react';

import ConsultantLayout from '../../layout/ConsultantLayout';
import PersonalInfoView from '../../views/consultant/PersonalInfoView';
const ConsultantResumePage = (): React.ReactElement => {
  return (
    <>
      <ConsultantLayout>
        <PersonalInfoView></PersonalInfoView>
      </ConsultantLayout>
    </>
  );
};

export default ConsultantResumePage;
