import React, { ReactElement } from 'react';
import { createStyles, WithStyles, withStyles } from '@material-ui/core';

import PersonalAccountNavigation from '../../components/PersonalAccountNavigation';
import PersonInfoView from '../../components/ResumeInputView/PersonInfoView';
const styles = createStyles({
  root: {
    minHeight: '100vh',
    width: '1286px',
    display: 'flex',
    margin: '0 auto',
    padding: '0',
  },
  left: {
    padding: '2rem 0',
    width: '256px',
  },
  right: {
    flex: '1',
    padding: '2rem',
  },

  attachment: {
    width: '180px',
    // padding: '2rem 1rem',
  },
  contentBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  textAttachment: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },

  textFont: {
    fontSize: '0.875rem',
  },
  attachmentCard: {
    marginTop: '2rem',
    padding: '1rem',
  },

  personal: {
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    wordWrap: 'break-word',
  },

  helperText: {
    color: 'red',
  },
  submit: {
    textAlign: 'center',
  },
  AvatarBorder: {
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    textAlign: 'center',
    '&:hover': {
      border: '10px',
    },
  },
  buttonHeight: {
    margin: 'auto 0',
    height: '36.5px',
  },
  avatarColor: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  contentTop: {
    borderRadius: '5px',
    backgroundColor: '#ffffff',
    padding: '1rem 2rem',
  },
});

const PersonalInfoView = ({ classes }: Props): ReactElement => {
  return (
    <div className={classes.root}>
      <div className={classes.left}>
        <PersonalAccountNavigation> </PersonalAccountNavigation>
      </div>
      <div className={classes.right}>
        <div style={{ borderRadius: '3px', background: '#ffffff', padding: '1rem' }}>
          <PersonInfoView />
        </div>
      </div>
    </div>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(PersonalInfoView);
